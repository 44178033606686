html {
  font-size: 62.5%;
  /* background-color: rgb(255, 255, 255); */
}

body {
  margin: 0;
  font-family:
    300 11px/1.4 'Helvetica Neue',
    sans-serif;
  color: #444;
  /* background-color: white; */
  /* overflow: hidden; */
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard {
  /* margin-top: 200px;
  margin-left: 200px; */
}

@media screen and (max-width: 1200px) {
  /* body {
    overflow: visible;
  } */
}

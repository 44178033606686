.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  justify-content: space-between;
  animation: fadeIn 1s forwards;
  // animation-delay: 1s;

  &.home-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    .text-zone {
      width: 100%;
    }
  }

  &.project-page {
    align-items: flex-start;
    .project-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &.contact-page,
  &.about-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    width: 100%;
    .text-zone {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .text {
        font-size: 12px;
        // color: #2d2d2d;
        font-family:
          Phi,
          -apple-system,
          BlinkMacSystemFont,
          Segoe UI,
          Roboto,
          Oxygen,
          Ubuntu,
          Cantarell,
          Fira Sans,
          Droid Sans,
          Helvetica Neue,
          sans-serif;
        margin-top: 10px;
      }
    }

    .text-animate-hover {
      &:hover {
        color: #b0b0b0;
      }
    }

    h1 {
      // font-size: 65px;
      // font-family: helvetica;
      // color: #4d4d4e;
      // font-weight: 100;
    }

    p {
      // font-size: 14px;
      color: #dedede;
      // font-family: helvetica;
      // font-weight: 100;
      // min-width: fit-content;
      // animation: pulse 1s;

      &:nth-last-of-type(1) {
        // animation-delay: 1.1s;
      }
      &:nth-last-of-type(2) {
        // animation-delay: 1.2s;
      }
      &:nth-last-of-type(3) {
        // animation-delay: 1.3s;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .App {
    // flex-direction: column;
  }
  .page {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .container {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    min-height: auto;

    &.home-page,
    &.contact-page,
    &.about-page,
    &.project-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      gap: 0;
      .text-zone {
        transform: none;
        width: 88%;
      }
    }
  }
}

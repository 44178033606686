.home-page {
  .text-zone {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .title-container {
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    gap: 30px;
    align-items: center;
    margin-top: 40px;
    animation: fadeIn 1s 0.5s backwards;
  }
  .pfp {
    width: 150px;
    height: auto;
    border-radius: 50%;
    box-shadow:
      0px 2px 15px 1px rgba(0, 0, 0, 0.2),
      0px 3px 10px 0px rgba(0, 0, 0, 0.04);
  }
  .pfp:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.08);
  }

  .title-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }

  .home-title {
    text-decoration: none;
    font-size: 60px;
    font-weight: 200;
    font-family:
      Phi,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      Fira Sans,
      Droid Sans,
      Helvetica Neue,
      sans-serif;
    margin: 0px;
    &:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.03);
    }
  }
  .home-title2 {
    text-decoration: none;
    font-weight: 200;
    font-size: 18px;
    margin: 0px;
    font-family:
      Phi,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      Fira Sans,
      Droid Sans,
      Helvetica Neue,
      sans-serif;
    &:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.03);
    }
  }
  .about-path {
    text-decoration: none;
  }

  .home-grid-container {
    margin-top: '0';
    width: 100%;
    grid-template-rows: 'auto';
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; /* Adjust the gap between items */
  }

  .logo-container {
    display: flex;
    z-index: 0;
    width: 600px;
    height: 600px;

    .solid-logo {
      z-index: 0;
      width: 100%;
      height: auto;
      animation: pulse 1.5s;
    }
  }

  @media screen and (max-width: 1200px) {
    .title-container {
      flex-direction: column-reverse;
    }

    .pfp {
      width: 220px;
    }
    .home-title {
      font-size: 40px;
      line-height: 43px;
    }

    .home-grid-container {
      width: auto;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
